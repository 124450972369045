import Img1 from "../images/partners/1.png";
import Img2 from "../images/partners/2.png";
import Img3 from "../images/partners/BSC.png";

const data = [
  { icon: Img1 },
  { icon: Img2 },
  { icon: Img3 },
  { icon: Img1 },
  { icon: Img2 },
  { icon: Img3 },
  { icon: Img1 },
  { icon: Img2 },
  { icon: Img1 },
  { icon: Img2 },
  { icon: Img3 },
  { icon: Img1 },
  { icon: Img2 },
  { icon: Img3 },
  { icon: Img1 },
  { icon: Img2 },
];

export default data;
